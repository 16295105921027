import { ref } from 'vue'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'

export default function useAcademies() {
  const RESOURCE = '/company'
  const { showSnackbar } = useSnackbar()
  const academies = ref([])
  const users = ref([])
  const isLoading = ref(false)
  const isUsersLoading = ref(false)

  const fetchAcademies = async () => {
    isLoading.value = true
    try {
      const response = await axios.get(RESOURCE, {
        headers: {
          'Content-Type': 'application/vnd.p72.app-1.0+json',
        },
      })
      academies.value = response.data
    } catch (error) {
      const message = error.response?.data?.message ?? error.message
      const statusCode = error.response?.status ?? null
      showSnackbar(
        `Failed to fetch academies:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      )
      throw error
    } finally {
      isLoading.value = false
    }
  }

  const fetchAcademyById = async (id) => {
    isLoading.value = true;
    try {
      const response = await axios.get(`${RESOURCE}/${id}`, {
        headers: {
          'Content-Type': 'application/vnd.p72.app-1.0+json',
        },
      });
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message ?? error.message;
      const statusCode = error.response?.status ?? null;
      showSnackbar(
        `Failed to fetch academy details:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      );
      throw error;
    } finally {
      isLoading.value = false;
    }
  };

  const fetchAcademyUsers = async (companyId) => {
    isUsersLoading.value = true
    try {
      const response = await axios.post(
        `${RESOURCE}/users/search`,
        { companyId },
        {
          headers: {
            'Content-Type': 'application/vnd.p72.app-1.0+json',
          },
        }
      )
      users.value = response.data.users
    } catch (error) {
      const message = error.response?.data?.message ?? error.message
      const statusCode = error.response?.status ?? null
      showSnackbar(
        `Failed to fetch users for academy:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      )
      throw error
    } finally {
      isUsersLoading.value = false
    }
  }

  const createAcademy = async academy => {
    try {
      await axios.post(RESOURCE, academy, {
        headers: {
          'Content-Type': 'application/vnd.p72.app-1.0+json',
        },
      })
      showSnackbar('Academy added successfully', SnackbarType.SUCCESS)
    } catch (error) {
      const message = error.response?.data?.message ?? error.message
      const statusCode = error.response?.status ?? null
      showSnackbar(
        `Failed to create academy:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      )
      throw error
    }
  }

  const updateAcademy = async academy => {
    try {
      await axios.put(`${RESOURCE}/${academy.id}`, academy, {
        headers: {
          'Content-Type': 'application/vnd.p72.app-1.0+json',
        },
      })
      showSnackbar('Academy updated successfully', SnackbarType.SUCCESS)
    } catch (error) {
      const message = error.response?.data?.message ?? error.message
      const statusCode = error.response?.status ?? null
      showSnackbar(
        `Failed to update academy:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      )
      throw error
    }
  }

  const deleteAcademy = async (academyId) => {
    try {
      await axios.delete(`${RESOURCE}/${academyId}`, {
        headers: {
          'Content-Type': 'application/vnd.p72.app-1.0+json',
        },
      })
      showSnackbar('Academy deleted successfully', SnackbarType.SUCCESS)
    } catch (error) {
      const message = error.response?.data?.message ?? error.message
      const statusCode = error.response?.status ?? null
      showSnackbar(
        `Failed to delete academy:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      )
      throw error
    }
  }  

  const searchUserByEmail = async (email) => {
    try {
      const response = await axios.post(
        '/user/search',
        { email },
        {
          headers: {
            'Content-Type': 'application/vnd.p72.app-1.0+json',
          },
        }
      )
      return response.data
    } catch (error) {
      console.error('Error during search:', error)
    }
  }

  const updateUser = async (user) => {
    try {
      await axios.put('/user/company', user, {
        headers: {
          'Content-Type': 'application/vnd.p72.app-1.0+json',
        },
      });
    } catch (error) {
      const message = error.response?.data?.message ?? error.message;
      const statusCode = error.response?.status ?? null;
      showSnackbar(
        `Failed to update user:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      );
      throw error;
    }
  };

  const getUserAcademy = async () => {
    try {
      const response = await axios.get('/user', {
        headers: {
          'Content-Type': 'application/vnd.p72.app-1.0+json',
        },
      });
      return response.data;
    } catch (error) {
      const message = error.response?.data?.message ?? error.message;
      const statusCode = error.response?.status ?? null;
      showSnackbar(
        `Failed to fetch user academy:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      );
      throw error;
    }
  };

  return {
    academies,
    users,
    isLoading,
    isUsersLoading,
    fetchAcademies,
    fetchAcademyById,
    fetchAcademyUsers,
    createAcademy,
    updateAcademy,
    deleteAcademy,
    searchUserByEmail,
    updateUser,
    getUserAcademy,
  }
}
